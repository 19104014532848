import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import s from './Sidebar.module.scss'
import LinksGroup from './LinksGroup/LinksGroup.js'
import { changeActiveSidebarItem } from '../../actions/navigation.js'
import SofiaLogo from '../Icons/SofiaLogo.js'
import cn from 'classnames'
import * as accessmanager from '../../services/AccessManager'
import loginImage from '../../assets/image/Blason_étoile_du_sahel.png'

const Sidebar = props => {
  const { activeItem = '', ...restProps } = props

  const [burgerSidebarOpen, setBurgerSidebarOpen] = useState(false)

  useEffect(() => {
    if (props.sidebarOpened) {
      setBurgerSidebarOpen(true)
    } else {
      setTimeout(() => {
        setBurgerSidebarOpen(false)
      }, 0)
    }
  }, [props.sidebarOpened])

  return (
    <nav className={cn(s.root, { [s.sidebarOpen]: burgerSidebarOpen })}>
      <header className={s.logo}>
        <img width={150} height={150} src={loginImage} alt='etoile_du_sahel' />
      </header>
      <ul className={s.nav}>
        <LinksGroup
          onActiveSidebarItemChange={activeItem =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header='Dashboard'
          isHeader
          iconName={<i className={'eva eva-home-outline'} />}
          link='/page/dashboard'
          index='dashboard'
        />

        {(accessmanager.isAdmin() || accessmanager.isPdv()) && (
          <LinksGroup
            onActiveSidebarItemChange={activeItem =>
              props.dispatch(changeActiveSidebarItem(activeItem))
            }
            activeItem={props.activeItem}
            header='Cartes'
            isHeader
            iconName={<i className={'eva eva-credit-card-outline'} />}
            link='/page/cards'
            index='cards'
          />
        )}
        {accessmanager.isAdmin() && (
          <LinksGroup
            onActiveSidebarItemChange={activeItem =>
              props.dispatch(changeActiveSidebarItem(activeItem))
            }
            activeItem={props.activeItem}
            header='Catégories'
            isHeader
            iconName={<i className={'fa fa-contao'} />}
            link='/page/categories'
            index='categories'
          />
        )}
        <LinksGroup
          onActiveSidebarItemChange={activeItem =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header='Abonnés'
          isHeader
          iconName={<i className={'eva eva-people-outline'} />}
          link='/page/subscribers'
          index='subscribers'
        />
          {accessmanager.isAdmin() && (
          <LinksGroup
            onActiveSidebarItemChange={activeItem =>
              props.dispatch(changeActiveSidebarItem(activeItem))
            }
            activeItem={props.activeItem}
            header='Commande'
            isHeader
            iconName={<i className={'eva eva-shopping-bag-outline'} />}
            link='/page/orders'
            index='orders'
          />
        )}
        {accessmanager.isAdmin() && (
          <LinksGroup
            onActiveSidebarItemChange={activeItem =>
              props.dispatch(changeActiveSidebarItem(activeItem))
            }
            activeItem={props.activeItem}
            header='Points de vente'
            isHeader
            iconName={<i className={'eva eva-shopping-cart-outline'} />}
            link='/page/pdvs'
            index='pdvs'
          />
        )}
        {accessmanager.isAdmin() && (
          <LinksGroup
            onActiveSidebarItemChange={activeItem =>
              props.dispatch(changeActiveSidebarItem(activeItem))
            }
            activeItem={props.activeItem}
            header='Utilisateurs'
            isHeader
            iconName={<i className={'eva eva-shield-outline'} />}
            link='/page/users'
            index='utilisateurs'
          />
        )}
        {/* {accessmanager.isAdmin() && (
          <LinksGroup
            onActiveSidebarItemChange={activeItem =>
              props.dispatch(changeActiveSidebarItem(activeItem))
            }
            activeItem={props.activeItem}
            header='Transaction'
            isHeader
            iconName={<i className={'eva eva-swap-outline'} />}
            link='/page/transactions'
            index='transaction'
          />
        )} */}
        {/* people-outline */}
      </ul>
      <div className='bg-widget d-flex mt-auto ml-1'></div>
    </nav>
  )
}

Sidebar.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  activeItem: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
}

function mapStateToProps (store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    activeItem: store.navigation.activeItem
  }
}

export default withRouter(connect(mapStateToProps)(Sidebar))
