import React, { useState } from 'react'
import Swal from 'sweetalert2'
import { useLayoutEffect, useEffect } from 'react'
import { ProgressBar, Dropdown } from 'react-bootstrap'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import financeimg from '../../assets/dashboard/svg/finance.png'
import documentimg from '../../assets/dashboard/svg/document.png'
import kioskimg from '../../assets/dashboard/svg/kiosk.png'
import teamimg from '../../assets/dashboard/svg/team.png'

import * as userservice from '../../services/UserService'
import './dashboard.css'
import * as accessmanager from '../../services/AccessManager'
import * as dashboardservice from '../../services/DashboardService'
import * as categoryservice from '../../services/CategoryService'
import * as localservice from '../../services/LocalStorage'

const Dashboard = () => {
  const [quantity, setQuantity] = useState(0)
  const [residuequantity, setResiduequantity] = useState(0)
  const [reserved, setReserved] = useState(0)
  const [sold, setSold] = useState(0)
  const [residuesold, setResiduesold] = useState(0)
  const [turnover, setTurnover] = useState(0)
  const [datacategories, setDatacategories] = useState([])
  const [fcategory, setFcategory] = useState('all')
  const [fcategoryname, setFcategoryname] = useState('Tous')
  const [datapdvs, setDatapdvs] = useState([])
  const [fpdv, setFpdv] = useState('all')
  const [fpdvname, setFpdvname] = useState('Tous')
  /* turnover */
  const msg = 'chez les points de vente'
  const getinf = async () => {
    if (accessmanager.isPdv()) {
      setFpdv(localservice.loadId())
    }
    setFcategoryname('Tous')
    for (var i = 0; i < datacategories.length; i++) {
      if (datacategories[i].id === fcategory) {
        setFcategoryname(datacategories[i].name)
        break
      }
    }
    if (accessmanager.isAdmin()) {
      setFpdvname('Tous')
      for (var c = 0; c < datapdvs.length; c++) {
        if (datapdvs[c].id === fpdv) {
          setFpdvname(
            datapdvs[c].pdv.name +
              ' : ' +
              datapdvs[c].firstname +
              ' ' +
              datapdvs[c].lastname
          )
          break
        }
      }
    }
    if (accessmanager.isPdv()) {
      setFpdv(localservice.loadId())
    }

    const res = await dashboardservice.getinfo(fcategory, fpdv)

    /*   {"quantity":26,"residuequantity":7,"reserved":19,"sold":8,"residuesold":18} */

    if (accessmanager.isAdmin()) {
      setQuantity(res.quantity)
      setResiduequantity(res.residuequantity)
    }
    setReserved(res.reserved)
    setSold(res.sold)
    setResiduesold(res.residuesold)
    setTurnover(res.turnover)
  }
  useEffect(() => {
    getinf()
  }, [fcategory, fpdv])

  useLayoutEffect(() => {
    getinfcategories()
    if (accessmanager.isAdmin()) {
      getinfpdvs()
    }
  }, [])
  const getinfcategories = async () => {
    var res = await categoryservice.getallcategoriessmall()
    if (res) {
      setDatacategories(res)
    }
  }
  const getinfpdvs = async () => {
    var res = await userservice.findallsmalldash(1, 'pdv')

    if (res) {
      setDatapdvs(res)
    }
  }
  return (
    <div>
      <div className='row'>
        <div className='col-md-3'>
          <p className='body-2'>Catégories</p>
          <div className='p-2 '>
            <Dropdown onSelect={e => setFcategory(e)}>
              <Dropdown.Toggle
                variant='btn btn-secondary dropdown-toggle'
                id='idcategory'
              >
                {fcategoryname}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item eventKey='all'>Tous</Dropdown.Item>
                {datacategories.map(option => (
                  <Dropdown.Item eventKey={option.id}>
                    {option.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className='p-2'></div>
          <div className='p-2'></div>
        </div>
        {accessmanager.isAdmin() && (
          <div className='col-md-3'>
            <p className='body-2'>Points de vente </p>
            <div className='p-2'>
              <Dropdown onSelect={e => setFpdv(e)}>
                <Dropdown.Toggle
                  variant='btn btn-secondary dropdown-toggle'
                  id='idpdv'
                >
                  {fpdvname}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey='all'>Tous</Dropdown.Item>
                  {datapdvs.map(option => (
                    <Dropdown.Item eventKey={option.id}>
                      {option.pdv.name} : {option.firstname}
                      {option.lastname}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className='p-2'></div>
            <div className='p-2'></div>
          </div>
        )}
      </div>
      <br />
      <br />
      <div className='row'>
        <div className='col-md-12'>
          <div className='row'>
            {accessmanager.isAdmin() && fpdv === 'all' && (
              <div className='col-md-3 grid-margin stretch-card average-price-card'>
                <div className='card texttheme'>
                  <div className='card-body'>
                    <div className='d-flex justify-content-between pb-2 align-items-center'>
                      <h2 className='font-weight-semibold mb-0'>{quantity}</h2>
                      <div /* className='icon-holder' */>
                        {/* <img className='icontheme1' src={financeimg} alt='logo' /> */}
                      </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                      <h5 className='font-weight-semibold mb-0'>Cartes</h5>
                      <p className='texttheme mb-0'></p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className='col-md-4 grid-margin stretch-card average-price-card'>
              <div className='card texttheme'>
                <div className='card-body'>
                  <div className='d-flex justify-content-between pb-2 align-items-center'>
                    <h2 className='font-weight-semibold mb-0'>{reserved}</h2>
                    <div /* className='icon-holder' */>
                      {/*   <img
                        className='icontheme3'
                        src={kioskimg}
                        alt='kioskimg'
                      /> */}
                    </div>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <h5 className='font-weight-semibold mb-0'>
                      Cartes {accessmanager.isAdmin() && fpdv === 'all' && msg}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-3 grid-margin stretch-card average-price-card'>
              <div className='card texttheme'>
                <div className='card-body'>
                  <div className='d-flex justify-content-between pb-2 align-items-center'>
                    <h2 className='font-weight-semibold mb-0'>
                      {accessmanager.isAdmin() &&
                        fpdv === 'all' &&
                        residuequantity}
                      {(accessmanager.isPdv() ||
                        (accessmanager.isAdmin() && fpdv !== 'all')) &&
                        reserved - sold}
                    </h2>
                    <div /* className='icon-holder' */>
                      {/* <img className='icontheme4' src={teamimg} alt='teamimg' /> */}
                    </div>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <h5 className='font-weight-semibold mb-0'>
                      Cartes restantes
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-3 grid-margin stretch-card average-price-card'>
              <div className='card texttheme'>
                <div className='card-body'>
                  <div className='d-flex justify-content-between pb-2 align-items-center'>
                    <h2 className='font-weight-semibold mb-0'>{sold}</h2>
                    <div /* className='icon-holder' */>
                      {/*  <img
                        className='icontheme2'
                        src={documentimg}
                        alt='documentimg'
                      /> */}
                    </div>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <h5 className='font-weight-semibold mb-0'>Abonnés</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-3 grid-margin stretch-card average-price-card'>
              <div className='card texttheme'>
                <div className='card-body'>
                  <div className='d-flex justify-content-between pb-2 align-items-center'>
                    <h2 className='font-weight-semibold mb-0'>{turnover}</h2>
                    <div /* className='icon-holder' */>
                      {/*  <img
                        className='icontheme2'
                        src={documentimg}
                        alt='documentimg'
                      /> */}
                    </div>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <h5 className='font-weight-semibold mb-0'>
                      Chiffre d'affaires
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
