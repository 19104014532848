import axios from './axios'
import Swal from 'sweetalert2'

import * as localstorage from './LocalStorage'
//{{url}}/api/category/get?page=0&size=1
export async function getallcategorypagination (page, size, name, description) {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    var filtre = ''
    if (name && name !== 'all') {
      filtre += '&name=' + name
    }
    if (description && description !== 'all') {
      filtre += '&description=' + description
    }
    let res = await axios
      .get(`/category/get?page=` + page + `&size=` + size + filtre)
      .catch(err => {
        return undefined
      })

    return res
  }
}
//{{url}}/api/category/findall
export async function findallcategory () {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    let res = await axios.get(`/category/findall`).catch(err => {
      return undefined
    })
    return res
  }
}

export async function addcategory (state) {
  var category = '{"name": "' + state.name +'","code":"' + state.code+'","prix":"' + state.prix
  if (
    state.description &&
    state.description !== null &&
    state.description !== undefined
  ) {
    category += '","description": "' + state.description
  }
  category += '" }'
  //console.log(category)
  const instance = axios.create({
    timeout: 100000,
    headers: { Authorization: 'Bearer ' + localstorage.loadAccess() }
  })
  let res = await instance
    .post(`/category/add/`, JSON.parse(category))
    .catch(err => {
      Swal.fire({
        title: 'Error!',
        text: err.message,
        icon: 'error',
        confirmButtonText: 'OK'
      })
      return undefined
    })
  return res
}
export async function getallcategoriessmall () {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    let res = await axios.get(`/category/findallsmall`).catch(err => {
      return []
    })
    return res?.data?.data?.rows
  }
  return []
}
export async function checkquantity () {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    let res = await axios.get(`/category/checkquantity`).catch(err => {
      return []
    })
    return res?.data?.data?.rows
  }
  return []
}