import React, { useState } from 'react'
import { useLayoutEffect, useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import { Checkmark } from 'react-checkmark'
import Modal from 'react-bootstrap/Modal'
import Footballloader from '../loader/footballloader'
import Switch from 'react-switch'
import Moment from 'react-moment'
import './../../assets/css/table.css'
import * as pdvservice from '../../services/PdvService'
import * as userservice from '../../services/UserService'
import { Box, Button, makeStyles, Typography } from '@material-ui/core'
import Swal from 'sweetalert2'
import { Edit, Visibility } from '@mui/icons-material'
import { Pagination } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
  button: {
    border: 'solid',
    borderWidth: '1px'
  },
  value: {
    fontWeight: 'bold',
    fontSize: '20px  !important',
    marginLeft: '12px  !important'
  },
  name: {
    fontSize: '18px  !important',
    color: ' rgb(107, 114, 128)'
  },

  categoryCard: {
    width: 'auto',
    minWidth: '150px',
    border: 'solid',
    padding: '10px',
    // padding: "4px",
    marginRight: '18px',
    borderBottomRightRadius: '25px',
    borderWidth: '1px',
    borderColor: ' #2196f3',
    textAlign: 'center'
  },
  flex: {
    display: 'flex'
  },
  ul: {
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        backgroundColor: '#e2101a',
        color: '#fff'
      }
    }
  }
}))
const Listpdvs = () => {
  const [loading, setLoading] = useState()
  const [totalItems, setTotalItems] = useState(0)
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [fstatus, setFstatus] = useState('all')
  const [data, setData] = useState([])
  const classes = useStyles()
  useEffect(() => {
    initdata()
  }, [page, fstatus])
  useLayoutEffect(() => {}, [])
  const initdata = async () => {
    setLoading(true)
    const res = await pdvservice.getallpdvpagination(
      page - 1,
      size,
      null,
      null,
      fstatus
    )
    if (res !== undefined && res !== null) {
      setLoading(false)
      const data0 = res.data.data.pdvs
      setData(data0)
      setTotalItems(res.data.data.totalItems)
      setTotalPages(res.data.data.totalPages)
    }
  }
  const updatepage = async index => {
    setPage(index + 1)
  }

  const updatestatus = event => {
    setFstatus(event.target.value)
  }
  const switchstatus = async (i, e, id, status) => {
    if (e === true && status === 0) {
      const res = await pdvservice.changerstatus(1, id)
      if (res !== undefined) {
        initdata()
      }
    } else if (e === false && status === 1) {
      const res = await pdvservice.changerstatus(0, id)
      if (res !== undefined) {
        initdata()
      }
    }
  }
  const createTable = () => {
    let table = []
    for (let i = 0; i < data.length; i++) {
      table.push(
        <>
          <tr key={i} id={data[i].id}>
            <td rowSpan={3}> {data[i].name} </td>
            <td rowSpan={3}> {data[i].address} </td>
            <td>Total: {data[i].stocks[0] ? data[i].stocks[0].quantity : 0}</td>
            <td>Total: {data[i].stocks[1] ? data[i].stocks[1].quantity : 0}</td>
            <td>
              Total : {data[i].stocks[2] ? data[i].stocks[2].quantity : 0}
            </td>
            <td rowSpan={3}>
              {' '}
              <h6>{data[i].turnover}</h6>
            </td>
            <td rowSpan={3}> {data[i].userId} </td>
            <td rowSpan={3}>
              <Switch
                onChange={e => switchstatus(i, e, data[i].id, data[i].enable)}
                checked={data[i].enable === 1 ? true : false}
              />
            </td>
            <td rowSpan={3}> {data[i].createdBy}</td>
            <td rowSpan={3}>
              <Moment format='DD-MM-YYYY hh:mm:ss'>{data[i].createdAt}</Moment>
            </td>
            <td rowSpan={3}>
              <Link to={`/page/showpdv/${data[i].id}`}>
                <Visibility style={{ cursor: 'pointer' }} />
              </Link>
              <Link to={`/page/updatepdv/${data[i].id}`}>
                <Edit className={classes.edit} />
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              Vendu: {data[i].stocks[0] ? data[i].stocks[0].soldnumber : 0}
            </td>
            <td>
              Vendu: {data[i].stocks[1] ? data[i].stocks[1].soldnumber : 0}
            </td>
            <td>
              Vendu: {data[i].stocks[2] ? data[i].stocks[2].soldnumber : 0}
            </td>
          </tr>
          <tr>
            <td>
              Reste: {data[i].stocks[0] ? data[i].stocks[0].residuenumber : 0}
            </td>
            <td>
              Reste: {data[i].stocks[1] ? data[i].stocks[1].residuenumber : 0}
            </td>
            <td>
              Reste: {data[i].stocks[2] ? data[i].stocks[2].residuenumber : 0}
            </td>
          </tr>
        </>
      )
    }
    return table
  }

  return (
    <div>
      <Modal centered show={loading}>
        <Footballloader />
      </Modal>
      <div className='row justify-content-md-center'>
        <div className='col-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <Row>
                <h3>Liste des points de ventes </h3>
                <Col xl lg='2'></Col>
                <Col xl lg='2'></Col>
                <Col xl lg='2'></Col>
                <Box className={classes.categoryCard}>
                  <Typography className={classes.name}>
                    Nombre total de points de ventes
                  </Typography>
                  <Typography className={classes.value}>
                    {totalItems}
                  </Typography>
                  {/* <i className='fa fa-download mr-2' /> */}
                </Box>
              </Row>
              <Row>
                <Col xl lg='1'></Col>
                <Col xl lg='3'></Col>
                <Col xl lg='3'></Col>
              </Row>
              <form className='form-sample'>
                <div className='container'>
                  <div className='row mt-4'>
                    <div className='col-md-6'>
                      <FormControl fullWidth>
                        <InputLabel id='demo-simple-select-label'>
                          Statut
                        </InputLabel>
                        <Select
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          label='Statut'
                          value={fstatus}
                          onChange={e => updatestatus(e)}
                        >
                          <MenuItem value='all'>{'Tous '}</MenuItem>
                          <MenuItem value='1'>{'activée '}</MenuItem>
                          <MenuItem value='0'>{'bloquée'}</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </form>

              <div className='mt-4 table-responsive'>
                <Container>
                  <Row className='justify-content-md-center'>
                    <Col xxl lg='4'></Col>
                    <Col xxl lg='4'></Col>
                    <Col xxl lg='4'>
                      <Link to='/page/addpdv'>
                        <button type='button' className='btn btn-primary-red'>
                          Ajouter un nouveau point de vente
                        </button>
                      </Link>
                    </Col>
                  </Row>
                </Container>

                <br />
                <div className='mt-4 table-responsive'>
                  {/* table-striped table-borderless table-hover  */}
                  <Table responsive className='table '>
                    <thead>
                      <tr>
                        <th> Nom </th>
                        <th> Adresse </th>
                        <th> Ghinois </th>
                        <th> Gradin </th>
                        <th> Virage </th>
                        <th> Recettes </th>
                        <th>Responsable </th>
                        <th> Activer </th>
                        <th> Crée par </th>
                        <th> Crée à </th>
                        <th> Actions </th>
                      </tr>
                    </thead>
                    <tbody>{createTable()}</tbody>
                  </Table>
                </div>
                <br />
                <br />
                <br />
                <Pagination
                  id='PaginationItem'
                  name='PaginationItem'
                  page={page}
                  count={totalPages}
                  shape='rounded'
                  classes={{ ul: classes.ul }}
                  boundaryCount={2}
                  showFirstButton
                  showLastButton
                  onChange={(e, newPage) => updatepage(newPage - 1)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Listpdvs
