import axios from './axios'
import Swal from 'sweetalert2'
import * as accessmanager from './AccessManager'
import * as localstorage from './LocalStorage'
//{{url}}/api/order/get?page=0&size=1
export async function getallorderpagination (
  page,
  size,
  status,
  startDate,
  endDate
) {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    var filtre = ''
    if (startDate && startDate !== 'all') {
      filtre += '&startDate=' + startDate
    }
    if (endDate && endDate !== 'all') {
      filtre += '&endDate=' + endDate
    }
    if (status && status !== 'all') {
      filtre += '&status=' + status
    }
    let res = await axios
      .get(`/order?page=` + page + `&size=` + size + filtre)
      .catch(err => {
        return undefined
      })

    return res
  }
}
//{{url}}/api/order/findall
export async function findallorder () {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    let res = await axios.get(`/order/findall`).catch(err => {
      return undefined
    })
    return res
  }
}
export async function getorder (id) {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    let res = await axios.get(`/order/` + id).catch(err => {
      return undefined
    })
    return res?.data
  }
}
/* https://ess-api.paypos.tn/api/order-client?orderId=e474e6a6-1e5c-4b0d-894e-74c6e356d8c3 */
export async function getorderclient (orderid) {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    var filtre = ''
    if (orderid && orderid !== null && orderid !== undefined) {
      filtre += '?orderId=' + orderid
    }

    let res = await axios.get(`/order-client` + filtre).catch(err => {
      return undefined
    })
    return res?.data
  }
}
/* https://ess-api.paypos.tn/api/category-online/386e41f9-0e50-4278-8acb-dc92b77a2add */
export async function getcategoryonline (categoryid) {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    let res = await axios.get(`/category-online/` + categoryid).catch(err => {
      return undefined
    })
    return res?.data
  }
}
/* {{url}}/api/order-client/84a2c81d-7445-444b-9c48-c9fac86dc92d */

export async function updatestatusDelivery (id, statusD) {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    console.log(id+'   '+ statusD)
    if (statusD && statusD !== null && statusD !== undefined) {
      const body = '{      "statusDelivery":' + statusD + ' }'
console.log(body)
      let res = await axios
        .patch(`/order-client/` + id, JSON.parse(body))
        .catch(err => {
          return undefined
        })
        console.log(body)
      return res?.data
    }
  }
}
