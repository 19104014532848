// -- React and related libs
import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, withRouter, Redirect } from 'react-router'

// -- Third Party Libs
import PropTypes from 'prop-types'

// -- Custom Components
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import Footer from '../Footer/Footer'
import Dashboard from '../../pages/dashboard/Dashboard'
import Listusers from '../../pages/users/listusers'
import Listcards from '../../pages/cards/listcards'
import Listsubscribers from '../../pages/subscribers/listsubscribers'
import Listpdvs from '../../pages/pdvs/listpdvs'
import Listcategories from '../../pages/categories/listcategories'
import Adduser from '../../pages/users/adduser'
import Addcard from '../../pages/cards/addcard'
import Addsubscriber from '../../pages/subscribers/addsubscriber'
import Addpdv from '../../pages/pdvs/addpdv'
import Addcategory from '../../pages/categories/addcategory'
import Profileuser from '../../pages/profile/profileuser'
import Editprofile from '../../pages/profile/editprofile'
import Listorders from '../../pages/orders/listorders'
// -- Component Styles
import s from './Layout.module.scss'
import DetailsPDV from '../../pages/pdvs/details'
import Detailsorder from '../../pages/orders/detailsorder'
const Layout = props => {
  return (
    <div className={s.root}>
      <div className={s.wrap}>
        <Header />
        <Sidebar />
        <main className={s.content}>
          {/*  <Breadcrumbs url={props.location.pathname} /> */}
          <br />
          <br />
          <Switch>
            <Route
              path='/page'
              exact
              render={() => <Redirect to='template/dashboard' />}
            />
            <Route path='/page/dashboard' exact component={Dashboard} />
            <Route path='/page/users' exact component={Listusers} />
            <Route path='/page/cards' exact component={Listcards} />
            <Route path='/page/subscribers' exact component={Listsubscribers} />
            <Route path='/page/pdvs' exact component={Listpdvs} />
            <Route path='/page/categories' exact component={Listcategories} />
            <Route path='/page/adduser' exact component={Adduser} />
            <Route path='/page/addcard' exact component={Addcard} />
            <Route path='/page/addsubscriber' exact component={Addsubscriber} />
            <Route path='/page/addpdv' exact component={Addpdv} />
            <Route path='/page/updatepdv/:id' exact component={Addpdv} />
            <Route path='/page/showpdv/:id' exact component={DetailsPDV} />
            <Route path='/page/addcategory' exact component={Addcategory} />
            <Route path='/page/profile/user' exact component={Profileuser} />
            <Route path='/page/profile/edit' exact component={Editprofile} />
            <Route path='/page/orders' exact component={Listorders} />
            <Route path='/page/showorder/:id' exact component={Detailsorder} />
            {/* Listorders */}
            <Route path='*' exact render={() => <Redirect to='/error' />} />
          </Switch>
        </main>
        <Footer />
      </div>
    </div>
  )
}

Layout.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired
}

function mapStateToProps (store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened
  }
}

export default withRouter(connect(mapStateToProps)(Layout))
